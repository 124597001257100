import React from 'react';
import { useState } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default () => (
  <StaticQuery
    query={graphql`
      query termsAndConditionsQuery {
        termsAndConditionsSnippet: markdownRemark(
          frontmatter: {
            type: { eq: "snippet" }
            id: { eq: "terms-and-conditions" }
          }
        ) {
          html
        }
      }
    `}
    render={data => (
      <TermsAndConditions content={data.termsAndConditionsSnippet.html} />
    )}
  />
);

export function TermsAndConditions(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      Tutustuthan kurssin{' '}
      <a href="" onClick={handleShow}>
        laskutus- ja peruutusehtoihin ennen
      </a>{' '}
      ilmoittautumista.
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Laskutus- ja peruutusehdot</Modal.Title>
        </Modal.Header>
        <Modal.Body
          dangerouslySetInnerHTML={{
            __html: props.content,
          }}
        ></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sulje ikkuna
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
