import React from 'react';
import { FaCheck } from 'react-icons/fa';
import style from './li.module.scss';

export default props => {
  return (
    <React.Fragment>
      <li className={style.item}>
        <div className={style.icon}>
          <FaCheck style={props.customStyle}></FaCheck>
        </div>
        <div
          className={style.text}
          dangerouslySetInnerHTML={{
            __html: props.children,
          }}
        ></div>
      </li>
    </React.Fragment>
  );
};
