import React from 'react';
import PropTypes from 'prop-types';
import TermsAndConditions from '../components/terms';
import loaderImage from '../images/ajax-loader.gif';

export function SignupForCourseSidebar({
  title,
  date,
  location,
  duration,
  price,
  url,
  mode = false,
}) {
  return (
    <>
      <h2 className="contact-us text-center mb-5">{title}</h2>
      <div className="course-information mb-3">
        <div className="date">
          <span className="info-label">Aika: </span>
          <span id="date">{date}</span>
        </div>
        <div className="location">
          <span className="info-label">Paikka: </span>
          <span id="location">
            <span className="loc1">{location}</span>
          </span>
        </div>
        <div className="duration">
          <span className="info-label">Kesto: </span>
          <span id="duration">{duration}</span>
        </div>
        {mode ? (
          <>
            <div className="price">
              <span className="info-label">Hinta: </span>
              <span id="price">
                <span
                  className="pr-2"
                  style={{ textDecoration: 'line-through' }}
                >
                  {price}
                </span>
                <span style={{ fontWeight: 'bold' }}>586 €</span> + alv (24%)
              </span>
            </div>
            <span style={{ color: '#019999' }}>
              15% alennus kaikille Joulukuun aikana ilmoittautuville.
            </span>
          </>
        ) : (
          <span id="price">{price} € + alv (24%)</span>
        )}
      </div>
      <p className="tos">
        <TermsAndConditions />
      </p>
      <div id="spinner">
        <img src={loaderImage} />
      </div>
      <div className="iframeWrapper contact">
        <iframe
          onLoad={() => {
            document.getElementById('spinner').style.display = 'none';
          }}
          frameBorder="0"
          src={url}
        ></iframe>
      </div>
    </>
  );
}

SignupForCourseSidebar.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  duration: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
};
