import React from 'react'
import { getKeyFromString } from '../utils/utils.js';
import Li from '../components/li'

export default function(props) {
  return (
    <div className="container-fluid widebox training-customization px-5 pt-7 pb-7">
      <div className="row flex-nowrap justify-content-between align-items-end">
        <div className="col-sm-12 text-center">
          <div className="h2 title text-uppercase mb-5">{props.headline}</div>
          <div className="description">
            {props.description}
            <div className="d-flex justify-content-center">
              <ul className="check-list text-left mt-4 mb-0">
                {props.items.map(item => (
                  <Li key={getKeyFromString(item)}>{item}</Li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
