import React from 'react';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import formulas1 from '../images/benefits/excel/formulas1.jpg';
import formulas2 from '../images/benefits/excel/formulas2.jpg';
import percentage from '../images/benefits/excel/percentage.jpg';
import average from '../images/benefits/excel/average.jpg';
import income1 from '../images/benefits/excel/income_statement_1.jpg';
import income2 from '../images/benefits/excel/income_statement_2.jpg';
import conditional1 from '../images/benefits/excel/conditional_format.jpg';
import conditional2 from '../images/benefits/excel/conditional_format2.jpg';
import headers1 from '../images/benefits/excel/headers_not_freezed.jpg';
import headers2 from '../images/benefits/excel/headers_freezed.jpg';
import shortcut1 from '../images/benefits/excel/shortcut_key1.jpg';
import shortcut2 from '../images/benefits/excel/shortcut_key2.jpg';

export default () => <Benefits></Benefits>;

export function Benefits(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="d-flex justify-content-center">
        <a className="btn cta-button-x2 small" href="" onClick={handleShow}>
          Katso esimerkkejä asioista, joita opit kurssilla
        </a>
      </div>
      <Modal
        className="practical-learnings"
        show={show}
        onHide={handleClose}
        size="lg"
        // dialogClassName="modal-90w"
      >
        <Modal.Header className="d-flex justify-content-center" closeButton>
          <Modal.Title className="a">
            Käytännön esimerkkejä asioista joita opit kurssilla.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center mt-3 mb-5">
            <Button variant="secondary" onClick={handleClose}>
              Sulje ikkuna
            </Button>
          </div>
          <h5 className="d-flex justify-content-center mb-4">
            Opi milloin ja miksi tarvitset kaavoissa $-merkkiä.
          </h5>
          <div className="row">
            <div className="col-md-5">
              <img src={formulas1} />
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </div>
            <div className="col-md-5">
              <img src={formulas2} />
            </div>
          </div>
          <hr />
          <h5 className="d-flex justify-content-center mt-6 mb-4">
            Opi tekemään prosenttilaskuja ja käyttämään oikein
            prosenttimuotoiluja.
          </h5>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-center">
              <img src={percentage} />
            </div>
          </div>
          <hr />
          <h5 className="d-flex justify-content-center mt-6 mb-4">
            Opi Excelin yleisimmmät funktiot. Tee taulukoistasi eri tilanteisiin
            "sopeutuvia" jos-funktion avulla.
          </h5>
          <div className="row">
            <div className="col-sm-4 d-flex align-items-center justify-content-center">
              <ul>
                <li>Summa</li>
                <li>Keskiarvo</li>
                <li>Minimi</li>
                <li>Maksimi</li>
                <li>Jos</li>
                <li>Ketjuta</li>
              </ul>
            </div>
            <div className="col-sm-8 d-flex justify-content-center">
              <img src={average} />
            </div>
          </div>
          <hr />
          <h5 className="d-flex justify-content-center mt-6 mb-4">
            Tee taulukoistasi luettavampia Excelin valmiiden ulkoasumallien
            avulla.
          </h5>
          <div className="row">
            <div className="col-md-5 d-flex justify-content-center">
              <img src={income1} />
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </div>
            <div className="col-md-5 d-flex justify-content-center">
              <img src={income2} />
            </div>
          </div>
          <hr />

          <h5 className="d-flex justify-content-center mt-6 mb-4">
            Korosta soluja ehdollisella muotoilulla.
          </h5>
          <div className="row">
            <div className="col-md-5 d-flex justify-content-center">
              <img src={conditional1} />
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </div>
            <div className="col-md-5 d-flex justify-content-center">
              <img src={conditional2} />
            </div>
          </div>
          <hr />

          <h5 className="d-flex justify-content-center mt-6 mb-4">
            Opi kiinnittämään otsikkorivit paikalleen kun "scrollaat" isoa
            taulukkoa alaspäin.
          </h5>
          <div className="row">
            <div className="col-md-5 d-flex justify-content-center">
              <img src={headers1} />
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </div>
            <div className="col-md-5 d-flex justify-content-center">
              <img src={headers2} />
            </div>
          </div>
          <hr />

          <h5 className="d-flex justify-content-center mt-6 mb-4">
            Opi tekemään usein tarvitsemasi toiminnot nopeasti,
            näppäinkomentojen avulla.
          </h5>
          <div className="row">
            <div className="col-md-5 d-flex justify-content-center">
              <img src={shortcut1} />
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-center">
              <i className="fa fa-arrow-right" aria-hidden="true"></i>
            </div>
            <div className="col-md-5 d-flex justify-content-center">
              <img src={shortcut2} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Sulje ikkuna
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
