import React from 'react';
import { getKeyFromString } from '../utils/utils.js';

export default props => {
  return (
    <div className="container-fluid widebox course-contents px-5 pt-7 pb-7">
      <div className="row flex-nowrap justify-content-between align-items-end">
        <div className="col-sm-12">
          <div className="h2 title text-center text-uppercase mb-6">
            {props.headline}
          </div>
          {/* Only show headline if we have one */}
          {props.headline_2 ? (
            <div className="headline_2 text-center mt-n5 mb-5">
              {props.headline_2}
            </div>
          ) : (
            undefined
          )}
          <div className="course-description-specific">
            <div className="d-flex justify-content-center">
              <div className="row">
                <div className="col-sm-6">
                  {props.example_content.column_1.map(subject => (
                    <div
                      key={getKeyFromString(subject.headline.fi)}
                      className="subject"
                    >
                      <p>
                        <strong>
                          <i
                            className={
                              'fa' +
                              (subject.headline.icon
                                ? ' ' + subject.headline.icon
                                : '')
                            }
                          ></i>
                          {subject.headline.fi}
                        </strong>
                      </p>

                      <ul>
                        {subject.bullets.fi.map(element => (
                          <li key={getKeyFromString(element)}>{element}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                <div className="col-sm-6">
                  {props.example_content.column_2.map(subject => (
                    <div
                      className="subject"
                      key={getKeyFromString(subject.headline.fi)}
                    >
                      <p>
                        <strong>
                          <i
                            className={
                              'fa' +
                              (subject.headline.icon
                                ? ' ' + subject.headline.icon
                                : '')
                            }
                          ></i>
                          {subject.headline.fi}
                        </strong>
                      </p>

                      <ul>
                        {subject.bullets.fi.map(element => (
                          <li key={getKeyFromString(element)}>{element}</li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
